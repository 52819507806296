import { SERVICETYCASE, SERVICETYPE } from '../constants/serviceType';
import { BOOKINGSTEPVALUE, SPEED } from '../constants/booking';

import { IRoute, IService, BOOKINGSTEP, IStatus } from '../types/booking';
import { IItem, IPrepareItem } from '../types/cart';

const correctServiceName = (service: string, msg: string) => (type: string) => `${SERVICETYCASE[service](type)} ${msg}`;

const getFullServiceName = (service: IService) => {
  const fullName = service.Employees.map(employee => `${employee.Employee.Surname} ${employee.Employee.Name}`);
  let serviceName = '';

  switch (service.ServiceType) {
    case SERVICETYPE.AIR: {
      const data = JSON.parse(service.Data);
      const places = data.Routes
        .map((route: IRoute) => `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`);
      serviceName = `${places.join('; ')}`;
      break;
    }
    case SERVICETYPE.HOTEL: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.hotel.Name}`;
      break;
    }
    case SERVICETYPE.TRAIN: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.StationDepart} ${data.StationArrive}`;
      break;
    }
    case SERVICETYPE.TRANSFER: {
      const data = JSON.parse(service.Data);
      serviceName = `${data.StartPlace.Name} ${data.EndPlace.Name}`;
      break;
    }
  }

  return correctServiceName(service.ServiceType, `${serviceName} ${fullName.join(' ')}`);
};

export const getProgress = (statuses: any[] = [], prevValue: any) => {
  const statusesLength = statuses.length || 1;
  const rawProgressValue = statuses
    .reduce((acc, { BookingStep }) =>
      acc + BOOKINGSTEPVALUE[BookingStep as keyof typeof BOOKINGSTEPVALUE],
    BOOKINGSTEPVALUE.Unknown) / statusesLength;
  const progressValue = rawProgressValue < prevValue ? prevValue : rawProgressValue;
  const progressSpeed = progressValue <= BOOKINGSTEPVALUE.PrepareToReserve ? SPEED.Slow : SPEED.Medium;

  return {
    progressValue,
    progressSpeed,
  };
};

export const mergeItemsWithStatuses = (
  cartItems: IPrepareItem[] = [],
  statuses: IStatus[] = [],
): IPrepareItem[] =>
// @ts-ignore
  cartItems.map((cartItem) => {
    const bookingStatus = statuses.find(({ CartItemId }) => cartItem.Id === CartItemId) || {};

    return {
      ...cartItem,
      bookingStatus,
    };
  });

export const updateCustomFields = (
  prevState: IPrepareItem[] = [],
  newState: IItem[] = [],
): IPrepareItem[] => {
  if (!prevState.length) {
    return newState;
  }

  return prevState.map((item: any) => {
    const currentItem = newState.find(({ Id }) => Id === item.Id);

    if (currentItem) {
      return {
        // @ts-ignore
        ...currentItem,
        isLoading: item.isLoading,
      };
    }

    return {
      ...item,
      isCanceled: true,
    };
  });
};

export const toggleItemLoading = (itemId: number, items: any[] = []) => {
  const index = items.findIndex(({ Id }) => Id === itemId);
  const hasItem = index >= 0;

  if (!hasItem) {
    return items;
  }

  return [
    ...items.slice(0, index),
    {
      ...items[index],
      isLoading: !items[index].isLoading,
    },
    ...items.slice(index + 1),
  ];
};

export const catchAndStopEvent = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

export const isItemReady = ({ BookingStep }: any) =>
  BookingStep === BOOKINGSTEP.ReadyToBook ||
    BookingStep === BOOKINGSTEP.Reserved ||
    BookingStep === BOOKINGSTEP.BookingError ||
    BookingStep === BOOKINGSTEP.ReservationError;

export const isTpDisrupted = ({ TravelPolicy }: any) => !!Object.keys(TravelPolicy).length;

export const filteredApprovers = (list: any[], login: string) => list.filter(({ Email }) => Email !== login);

export { correctServiceName, getFullServiceName };
