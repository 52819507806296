import { IconTypeSmartdesk } from 'new-ui/src/components/Icon/types';
import { getText } from '../../../i18n';

type IIconTypesSmaetdeskKeys = 'AIR' | 'HOTEL' | 'TRANSFER' | 'TRAIN' | 'VIP_HALL' | 'AEROEXPRESS' | 'OTHER' | 'TAXI' | 'TAXI_VOUCHER' | 'MICE';

const DEFAULT_FILTERS = {
  Cities: [],
  Companies: [],
  Statuses: [],
  Employees: [],
  Services: [],
  Tags: [],
  Projects: [],
  StartDate: null,
  EndDate: null,
  AnalyticValues: [],
  UserIsOwner: false,
  Departments: [],
  Labels: [],
};

const TRIPSTATUS = {
  BOOKED: 'Booked',
  CANCELLED: 'Cancelled',
  CANCELED: 'Canceled',
  AWAITINGCONFIRMATION: 'AwaitingConfirmation',
  AWAITINGTICKETING: 'AwaitingTicketing',
  COMPLETED: 'Completed',
  CREATED_EVENT: 'Created',
};

const FLIGHT_CERTIFICATE = 'FlightCertificate';

const TRIPSTATUSVALUES = {
  Booked: getText('constants:trips.tripStatusValues.booked'),
  Cancelled: getText('constants:trips.tripStatusValues.cancelled'),
  AwaitingConfirmation: getText('constants:trips.tripStatusValues.awaitingConfirmation'),
  AwaitingTicketing: getText('constants:trips.tripStatusValues.awaitingTicketing'),
  Completed: getText('constants:trips.tripStatusValues.completed'),
  Created: getText('constants:trips.tripStatusValues.createdEvent'),
};

const TRIPSTATUSES = new Map([
  [TRIPSTATUS.BOOKED, TRIPSTATUSVALUES.Booked],
  [TRIPSTATUS.CANCELLED, TRIPSTATUSVALUES.Cancelled],
  [TRIPSTATUS.AWAITINGCONFIRMATION, TRIPSTATUSVALUES.AwaitingConfirmation],
  [TRIPSTATUS.AWAITINGTICKETING, TRIPSTATUSVALUES.AwaitingTicketing],
  [TRIPSTATUS.COMPLETED, TRIPSTATUSVALUES.Completed],
  [TRIPSTATUS.CREATED_EVENT, TRIPSTATUSVALUES.Created],
]);

const SERVICE_SOURCE_TYPE = {
  BACKOFFICE: 'Backoffice',
};

const VERSIONSTATUS = {
  CREATED: 0,
  BOOKED: 1,
  CANCELLED: 2,
  METADATA: 3,
  HIDDEN: 4,
};

const STATUSES = {
  Booked: 0,
  Changed: 1,
  Canceled: 2,
};

const ERRORS_TYPES = {
  INTERVAL: 'Interval', // что-то пошло не так
  NO_HOTEL_ID: 'NoHotelId', // значит в jsonData нет id отеля или с ним проблема
  CHECK_IN_TIME_BEGAN: 'CheckInTimeBegan', // дата заезда уже наступила
};

const ICON_TYPES_SMARTDESK: Record<IIconTypesSmaetdeskKeys, IconTypeSmartdesk> = {
  AIR: 'smartdeskAir',
  HOTEL: 'smartdeskHotel',
  TRANSFER: 'smartdeskTransfer',
  TRAIN: 'smartdeskTrain',
  VIP_HALL: 'smartdeskVipHall',
  AEROEXPRESS: 'smartdeskAeroexpress',
  OTHER: 'smartdeskOther',
  TAXI: 'smartdeskTaxi',
  TAXI_VOUCHER: 'smartdeskTaxi',
  MICE: 'smartdeskMice',
};

const DATA_KEY = {
  NAME: 'Name',
  ADDITIONAL_INFO: 'AdditionalInfo',
  EVENT_DATE: 'EventDate',
  EVENT_TIME: 'EventTime',
};

enum EnumTripVersionStatus {
  Created = 0,
  Changed = 1,
  Canceled = 2,
  Metadata = 3,
  Hidden = 4,
}

export {
  TRIPSTATUS,
  TRIPSTATUSVALUES,
  TRIPSTATUSES,
  SERVICE_SOURCE_TYPE,
  VERSIONSTATUS,
  STATUSES,
  DEFAULT_FILTERS,
  ERRORS_TYPES,
  FLIGHT_CERTIFICATE,
  ICON_TYPES_SMARTDESK,
  DATA_KEY,
  EnumTripVersionStatus,
};
